import { DiscoveryApi, useApi, microsoftAuthApiRef } from "@backstage/core-plugin-api";
import { useAsync } from "react-use";


let msGraphToken1: any;
let msGraphToken2: any;
let msGraphToken3: any;
async function fetchAzureToken(discoveryApi: DiscoveryApi) {
    try {
        const baseUrl = await discoveryApi.getBaseUrl('msgraph');
        const response = await fetch(`${baseUrl}/v2/getAccessTokenWithoutScope`, {
            method: 'GET',
            headers: {
                'credentials': 'include',
            }
        });
        if (!response.ok) {
            throw new Error(`Failed to fetch contents, ${response.status} ${response.statusText}`);
        }
        const _token = await response.json();
        msGraphToken1 = _token;
        msGraphToken2 = _token;
        msGraphToken3 = _token;
    }
    catch (error: any) {
        console.error(`Error: ${error.message}`);
    }
};

export async function fetchMal(discoveryApi: DiscoveryApi) {
    try {
        await fetchAzureToken(discoveryApi);

        const baseUrl = await discoveryApi.getBaseUrl('msgraph');
        const response = await fetch(`${baseUrl}/v2/sharepoint/MAL/listAppsInfo`,
            {
                method: 'GET',
                headers: {
                    'credentials': 'include',
                    'Content-Type': 'application/json',
                    'accept': 'application/json',
                    Authorization: `Bearer ${msGraphToken1}`,
                }
            });
        if (!response.ok) {
            throw new Error(`Failed to fetch contents, ${response.status} ${response.statusText}`);
        }
        const data = await response.json();

        return data;


    }
    catch (error: any) {
        console.error(`Error: ${error.message}`);
    }
}

export async function fetchPdt(discoveryApi: DiscoveryApi) {
    try {
        await fetchAzureToken(discoveryApi);

        const baseUrl = await discoveryApi.getBaseUrl('msgraph');
        const response = await fetch(`${baseUrl}/v2/sharepoint/D&TProduct&TeamCatalogue/listProducts&TeamInfo`,
            {
                method: 'GET',
                headers: {
                    'credentials': 'include',
                    'Content-Type': 'application/json',
                    'accept': 'application/json',
                    Authorization: `Bearer ${msGraphToken3}`,
                }
            });
        if (!response.ok) {
            throw new Error(`Failed to fetch contents, ${response.status} ${response.statusText}`);
        }
        const data = await response.json();
        const filteredValue = data.filter((item: any) => item.productName !== "TBC");

        return filteredValue;


    }
    catch (error: any) {
        console.error(`Error: ${error.message}`);
    }
}


export async function fetchAlias(aliasValue: string, discoveryApi: DiscoveryApi) {
    try {
        await fetchAzureToken(discoveryApi);
        const baseUrl = await discoveryApi.getBaseUrl('msgraph');
        const query = `filter=fields%2FAlias+eq+%27${aliasValue}%27`;
        const response = await fetch(`${baseUrl}/v2/sharepoint/MAL/filteredMal?${query}`, {
            method: 'GET',
            headers: {
                'credentials': 'include',
                'Content-Type': 'application/json',
                'accept': 'application/json',
                Authorization: `Bearer ${msGraphToken2}`,
            }
        });

        if (!response.ok) {
            throw new Error(
                `Failed to fetch contents, ${response.status} ${response.statusText}`,
            );
        }
        const data = await response.json(); // this is returning a promise

        const aliasChecked = data.items[0].alias;
        return aliasChecked;
    }
    catch (error: any) {
        console.error(`Error: ${error.message}`);
    }
}


export async function fetchGithubToken(discoveryApi: DiscoveryApi): Promise<string> {
    const baseUrl = await discoveryApi.getBaseUrl('github');
    const response = await fetch(`${baseUrl}/getGithubToken`, {
        method: 'GET',
        headers: {
            'credentials': 'include',
        }
    });

    if (!response.ok) {
        throw new Error(`Failed to fetch contents, ${response.status} ${response.statusText}`);
    }

    const token = await response.text();
    return token;
}

export async function fetchGithubRepositoryExistence(repoName: string, token: any): Promise<{ exists: boolean, data?: any, message?: string }> {
    try {
        const url = `https://api.github.com/repos/DigitalInnovation/${repoName}`;
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/vnd.github.v3+json',
                "X-GitHub-Api-Version": '2022-11-28'
            }
        });

        if (response.ok) {
            const repositoryData = await response.json();
            return { exists: true, data: repositoryData, message: "Repository already exists" };
        } else if (response.status === 404) {
            return { exists: false, message: "Repository not found" };
        } else {
            throw new Error(`Failed to fetch repository, ${response.status} ${response.statusText}`);
        }
    } catch (error: any) {
        return { exists: false, message: error.message };
    }
}


export async function fetchGithubUserName(githubUserName: string, token: any) {
    const apiUrl = `https://api.github.com/orgs/DigitalInnovation/members/${githubUserName}`;

    try {
        const response = await fetch(apiUrl, {
            headers: {
                "X-GitHub-Api-Version": "2022-11-28",
                Accept: "application/vnd.github.v3+json",
                Authorization: "Bearer " + token,
            },
        });

        return response; // Return the entire response
    } catch (error) {
        console.error("Error fetching GitHub user information:", error);
        throw error; // Throw the error for the calling function to handle
    }
}




export function microsoftAzureToken() {
    const apiRef = useApi(microsoftAuthApiRef);
    const { value: token } = useAsync(async () => { return await apiRef.getAccessToken(); });
    return token;
}

export async function fetchAzureADGroups(adGroupName: any, token: any): Promise<any> {
    const graphApiUrl = `https://graph.microsoft.com/v1.0/groups?$search=%22displayName%3a${adGroupName}%22&$select=id,displayName`;
    const graphApiResponse = await fetch(graphApiUrl, {
        headers: {
            Authorization: `Bearer ${token}`,
            consistencyLevel: 'eventual',
        },
    });

    return graphApiResponse.json();
}


export async function fetchAzureADUsers(email: any, token: any): Promise<any> {
    try {
        const apiUrl = `https://graph.microsoft.com/v1.0/users?$filter=(userPrincipalName eq '${email}' or mail eq '${email}') and accountEnabled eq true`;
        const response = await fetch(apiUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
                consistencyLevel: 'eventual',
            },
        });

        if (response.ok) {
            const data = await response.json();
            return data.value.length > 0;
        } else {
            console.error('Error checking user:', response.statusText);
            return false;
        }
    } catch (error) {
        console.error('Error checking user:', error);
        return false;
    }
};

export const fetchAzureGroupEmail = async (groupId: string, token: any) => {   
    try {
        const response = await fetch(
            `https://graph.microsoft.com/v1.0/groups?$filter=mail eq 	%27${groupId}%27`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    ConsistencyLevel: 'eventual',
                },
            },
        );
        if (response.ok) {
            const data = await response.json();
            return data.value.length > 0;
        } else {
            console.error('Error checking user:', response.statusText);
            return false;
        }
    }
    catch (error) {
        console.error('Error checking user:', error);
        return false;
    }
}

export const validateEmail = (value: any, _checkRegex: any) => {
    const regex = new RegExp(_checkRegex);
    return regex.test(value);
};