import { Dialog, DialogTitle, DialogContent, FormControlLabel, Checkbox, DialogActions, Button, Chip } from "@material-ui/core";
import React from "react";


interface AppsecToolFormProps {
    appSecDialogOpen: boolean,
    handleAppSecDialogClose: () => void,
    selectedAppSecTools: string[],
    handleAppSecToolSelect: (_: any) => void,
    handleChipClick: (_: any) => void,
}

const AppsecToolForm: React.FC<AppsecToolFormProps> = ({
    appSecDialogOpen,
    handleAppSecDialogClose,
    selectedAppSecTools,
    handleAppSecToolSelect,

    handleChipClick,

}) => {
    return (
        <>

            <Dialog open={appSecDialogOpen} onClose={handleAppSecDialogClose} fullWidth={true} maxWidth='xs'>
                <DialogTitle>DevOps Tool Stack</DialogTitle>
                <DialogContent >

                    {['Snyk', 'Prisma', 'SonarCloud', 'New Relic', 'PagerDuty'].map((tool) => (
                        <div key={tool}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectedAppSecTools.includes(tool)}
                                        onChange={() => handleAppSecToolSelect(tool)}
                                    />
                                }
                                label={tool}
                            />
                        </div>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAppSecDialogClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            {selectedAppSecTools.length > 0 && (
                <div>
                    <Chip label={`Selected Tools: ${selectedAppSecTools.join(', ')}`} onClick={handleChipClick} />
                </div>
            )}
        </>
    );

}


export default AppsecToolForm;
