import {
  createBaseThemeOptions,
  PageTheme,
  createUnifiedTheme,
  genPageTheme,
} from '@backstage/theme';

import { colourDictionary } from './colors';

const shapes2: Record<string, string> = {
  blank: `url("data:image/svg+xml,%3csvg version='1.0' xmlns='http://www.w3.org/2000/svg' width='1400.000000pt' height='400.000000pt' viewBox='0 0 1400.000000 400.000000' preserveAspectRatio='xMidYMid meet'%3e %3cg transform='translate(0.000000%2c400.000000) scale(0.100000%2c-0.100000)' fill='black' stroke='none'%3e%3c/g%3e%3c/svg%3e")`,
};
const mnsPageTheme: Record<string, PageTheme> = {
  home: genPageTheme({
    colors: [
      colourDictionary.mnsTech.prussianBlue,
      colourDictionary.mnsTech.prussianBlue,
    ],
    shape: shapes2.blank,
  }),
  documentation: genPageTheme({
    colors: [
      colourDictionary.mnsTech.prussianBlue,
      colourDictionary.mnsTech.prussianBlue,
    ],
    shape: shapes2.blank,
  }),
  tool: genPageTheme({
    colors: [
      colourDictionary.mnsTech.prussianBlue,
      colourDictionary.mnsTech.prussianBlue,
    ],
    shape: shapes2.blank,
  }),
  service: genPageTheme({
    colors: [
      colourDictionary.mnsTech.prussianBlue,
      colourDictionary.mnsTech.prussianBlue,
    ],
    shape: shapes2.blank,
  }),
  website: genPageTheme({
    colors: [
      colourDictionary.mnsTech.prussianBlue,
      colourDictionary.mnsTech.prussianBlue,
    ],
    shape: shapes2.blank,
  }),
  library: genPageTheme({
    colors: [
      colourDictionary.mnsTech.prussianBlue,
      colourDictionary.mnsTech.prussianBlue,
    ],
    shape: shapes2.blank,
  }),
  other: genPageTheme({
    colors: [
      colourDictionary.mnsTech.prussianBlue,
      colourDictionary.mnsTech.prussianBlue,
    ],
    shape: shapes2.blank,
  }),
  app: genPageTheme({
    colors: [
      colourDictionary.mnsTech.prussianBlue,
      colourDictionary.mnsTech.prussianBlue,
    ],
    shape: shapes2.blank,
  }),
  apis: genPageTheme({
    colors: [
      colourDictionary.mnsTech.prussianBlue,
      colourDictionary.mnsTech.prussianBlue,
    ],
    shape: shapes2.blank,
  }),
};

export const customLightTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      type: 'light',
      background: {
        default: colourDictionary.grey[150],
      },
      status: {
        ok: colourDictionary.green[400],
        warning: colourDictionary.orange[500],
        error: colourDictionary.red[300],
        running: colourDictionary.mnsTech.prussianBlue,
        pending: colourDictionary.yellow[200],
        aborted: colourDictionary.grey[700],
      },
      bursts: {
        fontColor: colourDictionary.grey[50],
        slackChannelText: colourDictionary.grey[300],
        backgroundColor: {
          default: colourDictionary.red[400],
        },
        gradient: {
          linear:
            'linear-gradient(-137deg, colourDictionary.green[600] 0%, colourDictionary.green[800] 100%)',
        },
      },
      primary: {
        main: colourDictionary.grey[900],
        light: '#4C9AFF',
        dark: '#172B4D',
      },
      banner: {
        info: colourDictionary.mnsTech.prussianBlue,
        error: colourDictionary.red[300],
        text: colourDictionary.white,
        link: colourDictionary.orange[950],
      },
      white: colourDictionary.white,
      border: colourDictionary.white,
      textContrast: colourDictionary.black,
      textVerySubtle: colourDictionary.grey[50],
      textSubtle: colourDictionary.grey[600],
      highlight: colourDictionary.orange[100],
      errorBackground: colourDictionary.red[50],
      warningBackground: colourDictionary.yellow[100],
      infoBackground: colourDictionary.blue[200],
      errorText: colourDictionary.grey[400],
      infoText: colourDictionary.mnsTech.prussianBlue,
      warningText: colourDictionary.black,
      linkHover: colourDictionary.orange[950],
      link: colourDictionary.orange[950],
      gold: colourDictionary.orange[500],
      navigation: {
        background: colourDictionary.mnsTech.prussianBlue,
        indicator: colourDictionary.mnsTech.orange,
        color: colourDictionary.white,
        selectedColor: colourDictionary.mnsTech.orange,
        navItem: {
          hoverBackground: colourDictionary.mnsTech.prussianBlue,
        },
      },
      pinSidebarButton: {
        icon: colourDictionary.grey[900],
        background: colourDictionary.grey[400],
      },
      tabbar: {
        indicator: colourDictionary.mnsTech.prussianBlue,
      },
      search: {
        background: colourDictionary.white,
        border: colourDictionary.white,
        color: colourDictionary.white,
        '&:hover': {
          background: colourDictionary.white,
          outlineColor: colourDictionary.blue[500],
        },
        '&:focus': {
          background: colourDictionary.white,
        },
      },
    },
  }),
  fontFamily: 'Roboto, sans-serif',
  pageTheme: mnsPageTheme,
  defaultPageTheme: 'home',
  components: {
    BackstageHeader: {
      styleOverrides: {
        header: {
          backgroundColor: 'white',
          backgroundImage: 'none',
          boxShadow: 'none',
          '& *': {
            color: colourDictionary.black,
            fill: colourDictionary.black,
          },
        },
        title: {
          color: colourDictionary.black,
        },
        subtitle: {
          color: colourDictionary.black,
        },
      },
    },
    BackstageHeaderLabel: {
      styleOverrides: {
        root: {
          color: colourDictionary.black,
        },
        label: {
          color: colourDictionary.black,
        },
        value: {
          color: colourDictionary.black,
        },
      },
    },
    BackstageSidebarItem: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
        },
        selected: {
          '&$root': {
            borderLeft: 'solid 3px #F49F0A',
            color: colourDictionary.orange[950],
          },
        },
        highlightable: {
          '&:hover': {
            color: colourDictionary.orange[950],
          },
        },
        highlighted: {
          color: colourDictionary.orange[950],
        },
      },
    },
    BackstageTable: {
      styleOverrides: {
        root: {},
      },
    },
    BackstageTableHeader: {
      styleOverrides: {
        header: {},
      },
    },
    BackstageTableToolbar: {
      styleOverrides: {
        root: {},
        title: {},
        searchField: {},
      },
    },
    BackstageItemCardGrid: {
      styleOverrides: {
        root: {},
      },
    },
    BackstageHeaderTabs: {
      styleOverrides: {
        tabsWrapper: {},
        defaultTab: {},
        selected: {},
        tabRoot: {},
      },
    },
    BackstageContent: {
      styleOverrides: {
        root: {},
      },
    },
    BackstageContentHeader: {
      styleOverrides: {
        container: {},
        leftItemsBox: {},
        rightItemsBox: {},
        title: {},
      },
    },
    BackstageInfoCard: {
      styleOverrides: {
        header: {},
      },
    },
    BackstageItemCardHeader: {
      styleOverrides: {
        root: {
          color: colourDictionary.white,
        },
      },
    },
    BackstageOpenedDropdown: {
      styleOverrides: {
        icon: {
          '& path': {
            fill: '#FFFFFF',
          },
        },
      },
    },
    CatalogReactUserListPicker: {
      styleOverrides: {
        title: {
          textTransform: 'none',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
        standardError: ({ theme }) => ({
          color: '#FFFFFF',
          backgroundColor: theme.palette.error.dark,
          '& $icon': {
            color: '#FFFFFF',
          },
        }),
        standardInfo: ({ theme }) => ({
          color: '#FFFFFF',
          backgroundColor: theme.palette.primary.dark,
          '& $icon': {
            color: '#FFFFFF',
          },
        }),
        standardSuccess: ({ theme }) => ({
          color: '#FFFFFF',
          backgroundColor: theme.palette.success.dark,
          '& $icon': {
            color: '#FFFFFF',
          },
        }),
        standardWarning: ({ theme }) => ({
          color: theme.palette.grey[700],
          backgroundColor: theme.palette.secondary.light,
          '& $icon': {
            color: theme.palette.grey[700],
          },
        }),
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '&[aria-expanded=true]': {
            color: '#FFFFFF',
          },
          '&[aria-expanded=true] path': {
            fill: '#FFFFFF',
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(9,30,69,0.54)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '6px 30px',
          borderRadius: 3,
          textTransform: 'none',
        },
        contained: {
          boxShadow: 'none',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: 3,
          backgroundColor: theme.palette.grey[50],
          color: theme.palette.primary.dark,
          margin: 4,
        }),
      },
    },
    // TODO: (awanlin) - we get a type error for `root`, need to investigate
    MuiSelect: {
      styleOverrides: {
        select: {
          '&[aria-expanded]': {
            backgroundColor: '#26385A',
            color: '#FFFFFF',
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 10,
        },
        switchBase: {
          padding: 12,
        },
        thumb: {
          backgroundColor: '#FFFFFF',
          height: 14,
          width: 14,
        },
        track: {
          borderRadius: 9,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          transition: 'none',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        button: {
          textTransform: 'none',
        },
      },
    },
  },
});
