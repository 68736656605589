import React, { useState, useEffect } from 'react';
import { TextField, FormHelperText } from '@material-ui/core';
import { fetchGithubToken, fetchGithubUserName } from '../api';
import { useApi, discoveryApiRef } from '@backstage/core-plugin-api';
import { GITHUB_MAINTAINER_NAME, HELPER_TEXT_GITHUB_MAINTAINER_NAME} from '../../../../constants/ecosystemBuilderCreationConstants'

interface GithubRbacTeamMaintainerFormProps {
    githubUsername: string;
    handleGithubUsernameChange: (_: any) => void;
    githubValidationMessage: string;
    rbacError: boolean;
    setGithubValidationMessage: (message: string) => void;
    setRbacError: (error: boolean) => void;

}

const GithubRbacTeamMaintainerForm: React.FC<GithubRbacTeamMaintainerFormProps> = ({
    githubUsername,
    handleGithubUsernameChange,
    githubValidationMessage,
    rbacError,
    setGithubValidationMessage,
    setRbacError,

}) => {
    const [token, setToken] = useState('');
    const discoveryApi = useApi(discoveryApiRef);


    const fetchToken = async () => {
        try {
            const githubToken = await fetchGithubToken(discoveryApi);
            setToken(githubToken);
        } catch (error) {
            console.error('Error fetching GitHub token:', error);
        }
    };
    useEffect(() => {
        fetchGitUserInfo(githubUsername);
    },
        [githubUsername]
    );


    const fetchGitUserInfo = async (githubUsername: any) => {
        fetchToken();

        try {
            const response = await fetchGithubUserName(githubUsername, token);


            if (response.ok && (response.status === 200 || response.status === 204)) {
                setGithubValidationMessage('Valid GitHub username');
                setRbacError(false);
            }else if (githubUsername === '' || githubUsername === undefined || githubUsername === null) {
                    setGithubValidationMessage('');
                    setRbacError(false);
            }else {
                setGithubValidationMessage('User not found in the specified GitHub organization');
                setRbacError(true);
            }
        } catch (error) {
            console.error('Error parsing JSON or other fetchGitUserInfo error:', error);
            setRbacError(true);
            setGithubValidationMessage('Error validating GitHub username');
        }

    };

    return (
        <>
            <TextField
                label={GITHUB_MAINTAINER_NAME}
                value={githubUsername}
                onChange={handleGithubUsernameChange}
                fullWidth
                variant="outlined"
                margin='dense'
                error={rbacError}
                helperText={HELPER_TEXT_GITHUB_MAINTAINER_NAME}

            />
            {githubValidationMessage && <FormHelperText>{githubValidationMessage === 'Valid GitHub username' ?
                <div style={{ color: 'green' }}>{githubValidationMessage}</div> :
                <div style={{ color: 'red' }}>{githubValidationMessage}</div>}</FormHelperText>}
        </>
    );
};

export default GithubRbacTeamMaintainerForm;