import React from 'react';
import { TextField, MenuItem } from '@material-ui/core';
import AliasField from './AliasField';
import { validateEmail } from '../api';
import {
    APPLICATION_NAME,
    HELPER_TEXT_APPLICATION_NAME,
    DESCRIPTION,
    HELPER_TEXT_DESCRIPTION,
    IOCODE,
    HELPER_TEXT_IOCODE,
    RECOVERY_TIME_OBJECTIVE,
    HELPER_TEXT_RECOVERY_TIME_OBJECTIVE,
    DATA_CLASSIFICATION,
    HELPER_TEXT_DATA_CLASSIFICATION,
    LINK_TO_DOC,
    HELPER_TEXT_LINK_TO_DOC
} from '../../../../constants/ecosystemBuilderCreationConstants'

interface CommonFieldsProps {
    applicationNameValue: string;
    handleApplicationNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    aliasName: string;
    handleAliasChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    descriptionValue: string;
    handleDescriptionChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    ioCodeValue: string;
    handleIoCodeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    businessCriticalityValue: string;
    handleBusinessCriticalityChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    dataClassificationValue: string;
    handleDataClassificationChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    linkToDocumentationValue: string;
    handleLinkToDocumentationChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    AliasNameError: string;
    handleAliasNameError: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CommonFields: React.FC<CommonFieldsProps> = ({
    applicationNameValue,
    handleApplicationNameChange,
    aliasName,
    handleAliasChange,
    descriptionValue,
    handleDescriptionChange,
    ioCodeValue,
    handleIoCodeChange,
    businessCriticalityValue,
    handleBusinessCriticalityChange,
    dataClassificationValue,
    handleDataClassificationChange,
    linkToDocumentationValue,
    handleLinkToDocumentationChange,
    AliasNameError,
    handleAliasNameError,
}) => {
    const [appError, setAppError] = React.useState(false);
    const [descriptionError, setDescriptionError] = React.useState(false);
    const [iocodeError, setIocodeError] = React.useState(false);
    const [linkError, setLinkError] = React.useState(false);

    React.useEffect(() => {
        if (applicationNameValue === '') {
            setAppError(true);
        } else {
            const regex = /^[a-zA-Z0-9 ]{3,256}$/;
            const isAppNameValid = validateEmail(applicationNameValue, regex);
            if (!isAppNameValid) {
                setAppError(true);
            } else {
                setAppError(false);
            } 
        }
    }, [applicationNameValue]);


    React.useEffect(() => {
        if (descriptionValue === '') {
            setDescriptionError(true);
        } else {
            const regex = /^[a-zA-Z0-9 .-]{3,256}$/;
            const isDescriptionValid = validateEmail(descriptionValue, regex);
            if (!isDescriptionValid) {
                setDescriptionError(true);
            } else {
                setDescriptionError(false);
            }
        }
    }, [descriptionValue]);

    React.useEffect(() => {
        if (ioCodeValue === '') {
            setIocodeError(true);
        } else {
            const regex = /^[0-9a-zA-Z]+$/;
            const isIocodeValid = validateEmail(ioCodeValue, regex);
            if (!isIocodeValid) {
                setIocodeError(true);
            } else {
                setIocodeError(false);
            }
        }
    }, [ioCodeValue]);

    React.useEffect(() => {
        if (linkToDocumentationValue === '') {
            setLinkError(false);
        } else {
            const regex = /^(http|https):\/\/[^\s/$.?#].[^\s]*$/;
            const isLinkValid = validateEmail(linkToDocumentationValue, regex);
            if (!isLinkValid) {
                setLinkError(true);
            } else {
                setLinkError(false);
            }
        }
    }, [linkToDocumentationValue]); 


    return (
        <div style={{ marginBottom: '16px' }}>
            <TextField
                label={APPLICATION_NAME}
                value={applicationNameValue}
                onChange={handleApplicationNameChange}
                fullWidth
                margin='dense'
                variant='outlined'
                error={appError}
                helperText={appError ? "Application name is invalid" : HELPER_TEXT_APPLICATION_NAME}
            />
            <AliasField handleAliasChange={handleAliasChange} aliasName={aliasName} AliasNameError={AliasNameError}
                            handleAliasNameError={handleAliasNameError}/>
            <TextField
                label={DESCRIPTION}
                value={descriptionValue}
                onChange={handleDescriptionChange}
                fullWidth
                margin='dense'
                variant='outlined'
                error={descriptionError}
                helperText={descriptionError ? "Description is invalid" : HELPER_TEXT_DESCRIPTION}
            />
            <TextField
                label={IOCODE}
                value={ioCodeValue}
                onChange={handleIoCodeChange}
                fullWidth
                margin='dense'
                variant='outlined'
                error={iocodeError}
                helperText={iocodeError ? "Iocode is invalid" : HELPER_TEXT_IOCODE}
            />
            <TextField
                label={RECOVERY_TIME_OBJECTIVE}
                value={businessCriticalityValue}
                onChange={handleBusinessCriticalityChange}
                fullWidth
                margin='dense'
                variant='outlined'
                select
                helperText={HELPER_TEXT_RECOVERY_TIME_OBJECTIVE}
            >
                <MenuItem value="Day 1">Day 1</MenuItem>
                <MenuItem value="Day 3">Day 3</MenuItem>
                <MenuItem value="Day 7">Day 7</MenuItem>
                <MenuItem value="Month 1">Month 1</MenuItem>
            </TextField>
            <TextField
                label={DATA_CLASSIFICATION}
                value={dataClassificationValue}
                onChange={handleDataClassificationChange}
                fullWidth
                margin='dense'
                variant='outlined'
                select
                helperText={HELPER_TEXT_DATA_CLASSIFICATION}
            >
                <MenuItem value="Public">Public</MenuItem>
                <MenuItem value="Internal">Internal</MenuItem>
                <MenuItem value="Confidential - PCI and PII">Confidential - PCI and PII</MenuItem>
                <MenuItem value="Confidential - PCI">Confidential - PCI</MenuItem>
                <MenuItem value="Confidential - PII">Confidential - PII</MenuItem>
                <MenuItem value="Confidential - Other">Confidential - Other</MenuItem>
            </TextField>
            <TextField
                label={LINK_TO_DOC}
                value={linkToDocumentationValue}
                onChange={handleLinkToDocumentationChange}
                fullWidth
                margin='dense'
                variant='outlined'
                error={linkError}
                helperText={linkError ? "Link is invalid" : HELPER_TEXT_LINK_TO_DOC}
            />
        </div>
    );
};

export default CommonFields;