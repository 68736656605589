import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, Tooltip, Chip, makeStyles, IconButton, Typography, DialogActions } from '@material-ui/core';
import { fetchGithubRepositoryExistence, fetchGithubToken } from '../api';
import { useApi, discoveryApiRef } from '@backstage/core-plugin-api';
import { OVERRIDE_GIT_REPO, HELPER_TEXT_OVERRIDE_GIT_REPO, EXISTING_GIT_REPO, HELPER_TEXT_EXISTING_GIT_REPO } from '../../../../constants/ecosystemBuilderCreationConstants'
import AddIcon from '@material-ui/icons/Add';


interface GithubRepositoryFormProps {
  open: boolean;
  onClose: () => void;
  formdata: boolean;
  handleOverrideGithubRepoName: (_: any) => void;
  handleExistingGithubRepoName: (_: any) => void;
  GithubRepositoryError: string;
  handleGlobalError2: (_: any) => void;
  githubRepositoryCreateChecked: boolean;
  githubRepoName: string;
  existingGithubRepoName: string;
  setOpenGithubDialog: (isOpen: boolean) => void;
  setGithubRepositoryCreateChecked: (isChecked: boolean) => void;
  
}


const GithubRepositoryForm: React.FC<GithubRepositoryFormProps> = ({
  open,
  onClose,
  formdata,
  githubRepositoryCreateChecked,
  githubRepoName,
  existingGithubRepoName,
  handleGlobalError2,
  GithubRepositoryError,
  handleOverrideGithubRepoName,
  handleExistingGithubRepoName,
  setOpenGithubDialog,
  setGithubRepositoryCreateChecked,
}) => {
  
  const [available, setAvailable] = useState(false);
  const [existing, setExisting] = useState(false);
  const [token, setToken] = useState('');
  const [empty, setEmpty] = useState(true);
  const [errorText, setErrorText] = useState<string>('');
  const discoveryApi = useApi(discoveryApiRef);
  
  const useStyles = makeStyles({
    confirmButton: {
        float: 'right',
        // marginTop: '-55px',
        // marginRight: '20px',
    },
    
    });
const classes = useStyles();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const githubToken = await fetchGithubToken(discoveryApi);
        setToken(githubToken);
      } catch (error) {
        console.error('Error fetching GitHub token:', error);
      }
    };

    fetchData();
  }, [discoveryApi]);

  useEffect(() => {
    const fetchGithubRepoData = async () => {
      if (formdata && githubRepoName) {
        try {
          const githubRepoStatus = await fetchGithubRepositoryExistence(githubRepoName, token);

          if (githubRepoStatus.exists) {
            setExisting(true);
            handleGlobalError2('exists'); 
            GithubRepositoryError === 'exists';
            setErrorText('');
            setAvailable(false);
            setEmpty(false);
          } else if (githubRepoName === '' || githubRepoName === undefined || githubRepoName === null) {
            setExisting(false);
            setErrorText('Repository cannot be empty.');
            setAvailable(false);
            handleGlobalError2('doNotExists'); 
            GithubRepositoryError === 'doNotExists';            
            setEmpty(true);
          }
          else {
            setAvailable(true);
            setExisting(false);
            handleGlobalError2('doNotExists'); 
            GithubRepositoryError === 'doNotExists';
            setErrorText('Invalid Repository. Please enter a valid one.');
            setEmpty(false);
          }
        } catch (error) {
          console.error("Error fetching repository status:", error);
        }
      }
    };

    // Call the function to fetch data
    fetchGithubRepoData();
  }, [formdata, githubRepoName, token]);


const handleDialogClose = () => {
  if (githubRepoName.trim() !== '' && existing  ) {
    setOpenGithubDialog(false);
  }
};
 

  return (
    <>
      {githubRepositoryCreateChecked && (
        <>
          {/* <Tooltip title={`This Field is optional`} style={{fontSize: '16px', position: 'initial'}} placement='top'> */}
          <TextField
            label={OVERRIDE_GIT_REPO}
            value={githubRepoName}
            onChange={handleOverrideGithubRepoName}
            fullWidth
            margin='dense'
            variant="outlined"
            error={existing && !empty}
            helperText={empty ? HELPER_TEXT_OVERRIDE_GIT_REPO : (existing && !empty && !available ? 'Repository Name already exists' : 'Repository Name Available')}
          />
          {/* </Tooltip> */}
        </>
      )}
      {!githubRepositoryCreateChecked && (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
          <DialogTitle>Repository Name</DialogTitle>
          <DialogContent style={{ height: '110px' }}>
            <TextField
              label={EXISTING_GIT_REPO}
              value={githubRepoName}
              onChange={handleExistingGithubRepoName}
              fullWidth
              margin='dense'
              variant="outlined"
              error={!existing && !empty}
              helperText={empty ? HELPER_TEXT_EXISTING_GIT_REPO : (existing && !empty ? '' : 'Repository does not exist in DigitalInnovation')}             
            /> 
            {/* {existing ? GithubRepositoryError : ''} */}
            {/* {githubRepoName.trim() !== '' && (
              <Button className={classes.confirmButton}  variant="text" color="primary" onClick={handleDialogClose}>
                Confirm
              </Button>
            )} */}
          </DialogContent>
          <DialogActions>
            <IconButton className={classes.confirmButton} color='primary' onClick={handleDialogClose} disabled={!existing}>
                <AddIcon />
              </IconButton>
            {errorText && <Typography variant="caption" color="error">{errorText}</Typography>}  
          </DialogActions>
        </Dialog>
      )}
      <div>
        {!githubRepositoryCreateChecked && formdata && existingGithubRepoName.length >= 0 && (
          <>
            <Tooltip title={`Existing Github Repository`}>
              <Chip
                label={`Existing Github Repository Title: ${githubRepoName}`}
                color='primary'
                variant='outlined'
                onClick={() => {
                  setGithubRepositoryCreateChecked(false);
                  setOpenGithubDialog(true);
                }}
              />
            </Tooltip>
          </>
        )}
      </div>
    </>
  );
};

export default GithubRepositoryForm;