/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-console */
/* eslint-disable consistent-return */
import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Alert from '@material-ui/lab/Alert';
import { useAsync } from 'react-use';
import { makeFieldSchemaFromZod } from '@internal/plugin-scaffolder';
import { z } from 'zod';
import { AzureSubscription } from '../types';
import { getCookie, isTokenValid, setCookie } from '../../../utils';
import { useApi, microsoftAuthApiRef } from '@backstage/core-plugin-api';

// todo: move acquireTokenSilent() to template page after scope lookup for multi-custom-field templates.
type AzureSubscriptionOptions = {
  id: string;
  name: string;
};

type ResourceGroupOptions = {
  id: string;
  resourceGroupName: string;
  location: string;
  tags: Record<string, string>;
};

type AzureResourcePicker = {
  subscription: string;
  resourceGroup: string;
  vnet: string;
  subnet: string;
};

type ListOfVnet = {
  vnet_id: string;
  vnet_name: string;
};

type ListOfSubnet = {
  subnet_id: string;
  subnet_name: string;
};

function compareStrings(a: string, b: string) {
  a = a.toLowerCase();
  b = b.toLowerCase();
  return a < b ? -1 : a > b ? 1 : 0;
}

export const AzureNetworkExtension = ({
  onChange,
  schema,
  rawErrors,
  required,
  // formData,
  idSchema,
  uiSchema,
}: any) => {
  const [rgList, setRgList] = React.useState<ResourceGroupOptions[]>([]);
  const [vnetList, setvnetList] = React.useState<ListOfVnet[]>([]);
  const [subnetList, setsubnetList] = React.useState<ListOfSubnet[]>([]);

  const [userToken, setUserToken] = React.useState<string | undefined>();
  const [ResourceGroupLoader, setResourceGroupLoader] = React.useState(true);
  const [vnetLoader, setvnetLoader] = React.useState(true);
  const [subnetLoader, setsubnetLoader] = React.useState(true);

  const [resourceGroup, setResourceGroup] =
    React.useState<ResourceGroupOptions>();
  const [vnetValue, setvnet] = React.useState<ListOfVnet>();
  const [subnetValue, setsubnet] = React.useState<ListOfSubnet>();

  const [subscriptionDetails, setSubscriptionDetails] = React.useState<
    string | undefined
  >();
  const [selectedResourceGroup, setResourGroupDetails] = React.useState<
    string | undefined
  >();
  const [selectedvnet, setvnetDetails] = React.useState<string | undefined>();

  type AzureResourcePickerKeys = keyof AzureResourcePicker;

  let selectorFields: AzureResourcePickerKeys[] = uiSchema['ui:options']
    ?.selector
    ? (uiSchema['ui:options'].selector as AzureResourcePickerKeys[])
    : ['vnet', 'subnet'];

  if (!selectorFields.includes('vnet') && !selectorFields.includes('subnet')) {
    selectorFields = ['subscription', 'resourceGroup', 'vnet'];
  } else if (
    selectorFields.includes('vnet') &&
    !selectorFields.includes('subnet')
  ) {
    selectorFields = ['subscription', 'resourceGroup', 'vnet'];
  } else if (
    !selectorFields.includes('vnet') &&
    selectorFields.includes('subnet')
  ) {
    selectorFields = ['subscription', 'resourceGroup', 'vnet', 'subnet'];
  }

  const microsoft = useApi(microsoftAuthApiRef);

  // Fetch Azure subscription
  const { value, loading, error } = useAsync(async (): Promise<any> => {
    let token = getCookie('azureAccessToken') as string;

    if (!isTokenValid(token)) {
      token = await microsoft.getAccessToken(["https://management.azure.com/user_impersonation", "profile", "offline_access", "email", "openid"]);

      // Feat: Set token in a cookie with a 60-minute expiry
      setCookie('azureAccessToken', token, 60); // Set token in a cookie with a 60-minute expiry
    }
    if (userToken === undefined) {
      setUserToken(token);
    }
    return await fetch('https://management.azure.com/subscriptions/?api-version=2020-01-01', {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    }).then(res => res.json());

  }, [userToken]);

  if (error) {
    console.error(error);
    return (
      <Alert severity="error">
        Error could not fetch Azure subscriptions.{' '}
      </Alert>
    );
  }

  const azureSubscriptions: AzureSubscriptionOptions[] = [];

  try {
    value?.value?.map((data: AzureSubscription) =>
      azureSubscriptions.push({
        id: data.subscriptionId,
        name: data.displayName,
      }),
    );
  } catch (err: any) {
    console.error(err);
    <Alert severity="error">{err.message}</Alert>;
  }

  // fetch Azure Resource Groups
  async function fetchResourceGroupList(subscriptionId: string) {
    try {
      return await fetch(
        `https://management.azure.com/subscriptions/${subscriptionId}/resourcegroups?api-version=2021-04-01`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        },
      )
        .then(res => res.json())
        .then(data => data.value)
        .then(result =>
          result?.map((eachObj: any) => ({
            id: eachObj.id,
            resourceGroupName: eachObj.name,
            location: eachObj.location,
            tags: eachObj.tags,
          })),
        );
    } catch (error: any) {
      console.log('Could not fetch List of Resource Groups');
    }
  }

  // fetch Azure Vnet
  async function fetchvnet(rgId: string) {
    try {
      return await fetch(
        `https://management.azure.com${rgId}/providers/Microsoft.Network/virtualNetworks?api-version=2022-11-01`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        },
      )
        .then(res => res.json())
        .then(data => data.value)
        .then(result =>
          result?.map((eachObj: any) => ({
            vnet_id: eachObj.id,
            vnet_name: eachObj.name,
          })),
        );
    } catch (error: any) {
      console.log('Could not fetch List of Vnets');
    }
  }

  // fetch Azure Subnet
  async function fetchsubnet(vnetId: string) {
    try {
      return await fetch(
        `https://management.azure.com${vnetId}/subnets?api-version=2022-11-01`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        },
      )
        .then(res => res.json())
        .then(data => data.value)
        .then(result =>
          result?.map((eachObj: any) => ({
            subnet_id: eachObj.id,
            subnet_name: eachObj.name,
          })),
        );
    } catch (error: any) {
      console.log('Could not fetch List of Subnets ');
    }
  }

  // onchange for subscription
  const onSelectHandleSubscriptionChange = async (_event: any, value: any) => {
    setResourceGroupLoader(true);
    setResourceGroup(undefined);
    onChange(value);
    setSubscriptionDetails(value);

    // fetch call to get Resource Group function
    if (
      selectorFields &&
      selectorFields.includes('resourceGroup') &&
      value?.id !== null
    ) {
      const rgResponse = await fetchResourceGroupList(value.id || null);
      setRgList(rgResponse);
      setResourceGroupLoader(false);
    } else {
      setResourceGroupLoader(false);
    }
  };

  // onchnage for resourcegroup
  const onSelect = async (event: any, value: any) => {
    const resourceGroupDetails = value;
    onChange({ subscriptionDetails, resourceGroupDetails });

    setResourceGroup(event.target.value as ResourceGroupOptions);
    setResourGroupDetails(resourceGroupDetails);

    // fetch call to get Vnet
    if (
      selectorFields &&
      selectorFields.includes('vnet') &&
      value?.id !== null
    ) {
      const vnetResponse = await fetchvnet(value.id || null);
      setvnetList(vnetResponse);
      setvnetLoader(false);
    } else {
      setvnetLoader(false);
    }
  };

  // On changes for Vnet
  const onvnetSelect = async (event: any, value: any) => {
    setsubnetLoader(true);
    setsubnetList([]);
    const vnetDetails = value;
    onChange({ subscriptionDetails, selectedResourceGroup, selectedvnet });
    setvnet(event.target.value as ListOfVnet);
    setvnetDetails(vnetDetails);

    // fetch call to get subnet
    if (
      selectorFields &&
      selectorFields.includes('subnet') &&
      value?.vnet_id !== null
    ) {
      const subnetResponce = await fetchsubnet(value.vnet_id || null);
      setsubnetList(subnetResponce);
      setsubnetLoader(false);
    } else {
      setsubnetLoader(false);
    }
  };

  const onsubnetSelect = (event: any, value: any) => {
    const subnetDetails = value;
    onChange({
      subscriptionDetails,
      selectedResourceGroup,
      selectedvnet,
      subnetDetails,
    });
    setsubnet(event.target.value as ListOfSubnet);
  };

  //   Azure subscription Props
  const AzureSubscriptionProps = {
    options: azureSubscriptions.sort(
      (a: AzureSubscriptionOptions, b: AzureSubscriptionOptions) => {
        return compareStrings(a.name, b.name);
      },
    ),
    getOptionLabel: (option: AzureSubscriptionOptions) => option.name,
    getOptionSelected: (
      option: AzureSubscriptionOptions,
      value: AzureSubscriptionOptions,
    ) => option.name === value.name,
  };

  //   ResourceGroup Props
  const resourceGroupProps = {
    options: rgList.sort((a: ResourceGroupOptions, b: ResourceGroupOptions) => {
      return compareStrings(a.resourceGroupName, b.resourceGroupName);
    }),
    getOptionLabel: (option: ResourceGroupOptions) => option.resourceGroupName,
    getOptionSelected: (
      option: ResourceGroupOptions,
      value: ResourceGroupOptions,
    ) => option.resourceGroupName === value.resourceGroupName,
  };

  // Vnet Props
  const vnetProps = {
    options: vnetList.sort((a: ListOfVnet, b: ListOfVnet) => {
      return compareStrings(a.vnet_name, b.vnet_name);
    }),
    getOptionLabel: (option: ListOfVnet) => option.vnet_name,
    getOptionSelected: (option: ListOfVnet, value: ListOfVnet) =>
      option.vnet_name === value.vnet_name,
  };

  // subnet Props
  const subnetProps = {
    options: subnetList.sort((a: ListOfSubnet, b: ListOfSubnet) => {
      return compareStrings(a.subnet_name, b.subnet_name);
    }),
    getOptionLabel: (option: ListOfSubnet) => option.subnet_name,
    getOptionSelected: (option: ListOfSubnet, value: ListOfSubnet) =>
      option.subnet_name === value.subnet_name,
  };

  // i stopped here

  const Title = schema.title || 'Azure Subscription';

  return (
    <>
      <FormControl
        margin="normal"
        required={required}
        error={rawErrors?.length > 0}
      >
        {!selectorFields ||
          (selectorFields && selectorFields.includes('subscription') && (
            <Autocomplete
              id={idSchema?.$id}
              onChange={onSelectHandleSubscriptionChange}
              loading={loading}
              {...AzureSubscriptionProps}
              clearOnEscape
              // need to add on inout change
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  label={Title}
                  variant="standard"
                  required={required}
                />
              )}
            />
          ))}
        {schema.description && (
          <FormHelperText>{schema.description}</FormHelperText>
        )}
      </FormControl>

      <FormControl
        margin="normal"
        required={required}
        error={rawErrors?.length > 0}
      >
        {selectorFields && selectorFields.includes('resourceGroup') && (
          <Autocomplete
            id={idSchema?.$id}
            onChange={onSelect}
            loading={ResourceGroupLoader}
            {...resourceGroupProps}
            clearOnEscape
            value={resourceGroup}
            renderInput={(params: any) => (
              <TextField
                {...params}
                label="Resource Group"
                variant="standard"
                required={required}
              />
            )}
          />
        )}
        {schema.description && (
          <FormHelperText>Choose the required Resource Group</FormHelperText>
        )}
      </FormControl>

      <FormControl
        margin="normal"
        required={required}
        error={rawErrors?.length > 0}
      >
        {selectorFields && selectorFields.includes('vnet') && (
          <Autocomplete
            id={idSchema?.$id}
            value={vnetValue}
            onChange={onvnetSelect}
            loading={vnetLoader}
            {...vnetProps}
            clearOnEscape
            renderInput={(params: any) => (
              <TextField
                {...params}
                label="List of Vnets"
                variant="standard"
                required={required}
              />
            )}
          />
        )}
        {schema.description && (
          <FormHelperText>Choose the required Vnet</FormHelperText>
        )}
      </FormControl>

      <FormControl
        margin="normal"
        required={required}
        error={rawErrors?.length > 0}
      >
        {selectorFields && selectorFields.includes('subnet') && (
          <Autocomplete
            id={idSchema?.$id}
            value={subnetValue}
            onChange={onsubnetSelect}
            loading={subnetLoader}
            {...subnetProps}
            clearOnEscape
            renderInput={(params: any) => (
              <TextField
                {...params}
                label="List of Subnets"
                variant="standard"
                required={required}
              />
            )}
          />
        )}
        {schema.description && (
          <FormHelperText>Choose the required Subnet</FormHelperText>
        )}
      </FormControl>
    </>
  );
};

export const AzureNetworkExtensionWithOptionsSchema = makeFieldSchemaFromZod(
  z.object({}),
  z.object({
    selector: z
      .array(z.enum(['subnet', 'vnet']))
      .optional()
      .describe('Allow user Select the Field in the form. Defaults to []'),
  }),
).schema;
